<template>
  <p-container>
    <template slot="left">
      <a-tree
        :show-line="true"
        :default-expanded-keys="['0-0']"
        :tree-data="treeData"
      ></a-tree>
    </template>

    <template slot="searchbar">
      <a-form layout="inline">
        <a-form-item label="单位类型">
          <a-select placeholder="单位类型" style="width: 120px">
            <a-select-option value="fm">发明</a-select-option>
            <a-select-option value="syxx">实用新型</a-select-option>
            <a-select-option value="wgsj">外观设计</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="专利类型">
          <a-select placeholder="专利类型" style="width: 120px">
            <a-select-option value="fm">发明</a-select-option>
            <a-select-option value="syxx">实用新型</a-select-option>
            <a-select-option value="wgsj">外观设计</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-select style="width: 120px">
            <a-select-option value="fm">发明</a-select-option>
            <a-select-option value="syxx">实用新型</a-select-option>
            <a-select-option value="wgsj">外观设计</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-input style="width: 120px" />
        </a-form-item>

        <a-form-item>
          <a-button type="primary" html-type="submit">
            <a-icon type="search" />检索
          </a-button>
        </a-form-item>
      </a-form>
    </template>

    <a-table
      bordered
      :data-source="dataSource"
      :columns="columns"
      size="middle"
      :row-selection="rowSelection"
    >
    <div slot="action">
      <a href="javascript:;"><a-icon type="form" /></a>
    </div>
    </a-table>
  </p-container>
</template>

<script>
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
};
const treeData = [
  {
    title: "全部",
    key: "0-0",
    children: [
      {
        title: "直属科研单位",
        key: "0-0-0",
      },
      {
        title: "直属产业单位",
        key: "0-0-1",
      },
      {
        title: "直属省公司",
        key: "0-0-2",
      },
    ],
  },
];
const columns = [
  {
    title: "指标层面",
    dataIndex: "key"
  },
  {
    title: "指标内容",
    dataIndex: "name"
  },
  {
    title: "专利类型",
    dataIndex: "age",
  },
  {
    title: "权重",
    dataIndex: "address",
  },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: "action" },
  }
];
export default {
  data() {
    return {
      treeData,
      rowSelection,
      dataSource: [
        {
          key: "法律层面",
          name: "专利撰写质量",
          age: "发明",
          address: "10",
        },
      ],
      columns,
    };
  },
  methods: {
    handleEdit() {},
  },
};
</script>

<style>
</style>